import { Button, Col, Form, Input, Row, Space } from "antd";

const SOAPConfiguration = ({show, client, onBack, onSave, onClose}: any) => {

    const [form] = Form.useForm();
  
    const requiredRule = (isRequired: boolean, message: string) => {
        return {
          required: isRequired,
          message: message,
        }
    }

    const onFinish = (soapChannel: any) => {
        const data = {...client, ...soapChannel};
        onSave(data);
    }
    
    return <Form 
        form={form}
        className="clients-page-form"
        onFinish={onFinish}
        style={{overflow: 'hidden', height: show ? 'auto' : '0rem'}}
        >
  
        <h2>SOAP Configuration</h2>
        <h3>Network Configuration</h3>
        <Row gutter={16}>
            <Col span={6}>
            <label>Protocol *</label>
            <Form.Item
                name="targetProtocol"
                rules={[requiredRule(client?.channelType === 'SOAP', "Protocol is required.")]}
            >
                <Input />
            </Form.Item>
            </Col>
            <Col span={18}>
            <label>Host</label>
            <Form.Item
                name="targetHost"
                rules={[requiredRule(client?.channelType === 'SOAP', "Host is required.")]}
            >
                <Input />
            </Form.Item>
            </Col>
        </Row>
        <label>Endpoint</label>
        <Form.Item
            name={['soapChannel', 'targetEndpoint']}
            rules={[requiredRule(client?.channelType === 'SOAP', "Target Endpoint is required.")]}
        >
            <Input />
        </Form.Item>
        <label>Namespace URI</label>
        <Form.Item
            name={['soapChannel', 'targetNamespaceUri']}
            rules={[requiredRule(client?.channelType === 'SOAP', "Namespace URI is required.")]}
        >
            <Input />
        </Form.Item>

        <Space direction="horizontal" align="end" style={{marginTop: '2rem', width: '100%', justifyContent: 'flex-end'}}>
            <Button type="default" key="cancel" onClick={onClose}>Cancel</Button>
            <Button type="primary" onClick={onBack}>Back</Button>
            <Button type="primary" htmlType="submit">Save</Button>
        </Space>
    </Form>
}

export default SOAPConfiguration;