import { Button, Col, Form, Input, Row, Select, Space } from "antd";
import { useState } from "react";

const RESTMethods = [
    { label: 'PUT', value: 'PUT' },
    { label: 'POST', value: 'POST' }
]

const grantTypes = [
    { label: 'Client Credentials', value: 'CLIENT_CREDENTIALS' },
    { label: 'Password', value: 'PASSWORD' },
]
  
const RESTConfiguration = ({show, client, onBack, onSave, onClose}: any) => {

    const [targetGrantType, setTargetGrantType] = useState<string>();
    const [form] = Form.useForm();
  
    const requiredRule = (isRequired: boolean, message: string) => {
        return {
          required: isRequired,
          message: message,
        }
    }

    const onFinish = (channel: any) => {
        const restChannel = {...channel, targetGrantType};
        const data = {...client, ...restChannel};
        onSave(data);
    }
    
    return <Form 
        form={form}
        className="clients-page-form"
        onFinish={onFinish}
        style={{overflow: 'hidden', height: show ? 'auto' : '0rem'}}
        >
  
        <h2>RESTful Configuration</h2>

        <h3>Network Configuration</h3>
        <Row gutter={16}>
            <Col span={6}>
            <label>Protocol *</label>
            <Form.Item
                name="targetProtocol"
                rules={[requiredRule(client?.channelType == 'REST',  "Protocol is required.") ]}
            >
                <Input />
            </Form.Item>
            </Col>
            <Col span={18}>
            <label>Host *</label>
            <Form.Item
                name="targetHost"
                rules={[requiredRule(client?.channelType == 'REST',  "Host is required.") ]}
            >
                <Input />
            </Form.Item>
            </Col>
        </Row>
        <Row gutter={16}>
            <Col span={6}>
            <label>Request Method *</label>
            <Form.Item
                name={['restChannel', 'requestMethod']}
                rules={[requiredRule(client?.channelType == 'REST',  "Method is required.") ]}
            >
                <Select options={RESTMethods} />
            </Form.Item>
            </Col>
            <Col span={18}>
            <label>Target Endpoint *</label>
            <Form.Item
                name={['restChannel', 'targetAuthEndpoint']}
                rules={[requiredRule(client?.channelType == 'REST',  "Target Endpoint is required.") ]}
            >
                <Input />
            </Form.Item>
            </Col>
        </Row>
    
        <h3>OAuth 2.0</h3>
        <label>Grant Type *</label>
        <Form.Item
            name={['restChannel', 'targetGrantType']}
            rules={[requiredRule(true, "Grant Type is required.")]}
        >
            <Select options={grantTypes} onChange={setTargetGrantType} />
        </Form.Item>
            <label>Client ID *</label>
            <Form.Item
                name={['restChannel', 'targetClientId']}
                rules={[requiredRule(client?.channelType == 'REST',  "Client ID is required.") ]}
            >
            <Input />
            </Form.Item>
            <label>Client Secret</label>
            <Form.Item
                name={['restChannel', 'targetClientSecret']}
            >
            <Input.Password />
            </Form.Item>
        
        {('PASSWORD' === targetGrantType) &&
        <>
            <h3>User Credentials</h3>
            <Row gutter={16}>
            <Col span={12}>
                <label>Username *</label>
                <Form.Item
                    name={['restChannel', 'targetUsername']}
                    rules={[requiredRule(targetGrantType === 'PASSWORD', "Username is required.")]}
                >
                <Input />
                </Form.Item>
            </Col>
            <Col span={12}>
                <label>Password *</label>
                <Form.Item
                name={['restChannel', 'targetPassword']}
                    rules={[requiredRule(targetGrantType === 'PASSWORD', "Password is required.")]}
                >
                <Input.Password />
                </Form.Item>
            </Col>
            </Row>
        </>}
  
        <Space direction="horizontal" align="end" style={{marginTop: '2rem', width: '100%', justifyContent: 'flex-end'}}>
            <Button type="default" key="cancel" onClick={onClose}>Cancel</Button>
            <Button type="primary" onClick={onBack}>Back</Button>
            <Button type="primary" htmlType="submit">Save</Button>
        </Space>
    </Form>
}

export default RESTConfiguration;