import SFTPConfiguration from "./sftp-configuration";
import RESTConfiguration from "./rest-configuration";
import SOAPConfiguration from "./soap-configuration";
import NoChannelConfiguration from "./no-channel-configuration";

const TargetConfiguration = ({show, client, onBack, onSave, onClose}: any) => {

    return <div className="clients-page-form"
        style={{overflow: 'hidden', height: show ? 'auto' : '0rem'}}>
        <SFTPConfiguration client={client} 
            show={client?.channelType === 'sFTP'} 
            onBack={onBack} 
            onSave={onSave} 
            onClose={onClose}
        />
        <RESTConfiguration client={client} 
            show={client?.channelType === 'REST'} 
            onBack={onBack} 
            onSave={onSave} 
            onClose={onClose}
        />
        <SOAPConfiguration client={client} 
            show={client?.channelType === 'SOAP'} 
            onBack={onBack} 
            onSave={onSave} 
            onClose={onClose}
        />
        <NoChannelConfiguration client={client} 
            show={client?.channelType == null} 
            onBack={onBack} 
            onSave={onSave} 
            onClose={onClose}
        />

    </div>
}

export default TargetConfiguration;