import { Button, Col, Form, Input, Radio, RadioChangeEvent, Row, Select, Space, } from "antd";
import { useEffect, useState } from "react";

const grantTypes = [
    { label: 'Client Credentials', value: 'CLIENT_CREDENTIALS' },
    { label: 'Password', value: 'PASSWORD' },
]
  
const SourceConfiguration = ({show, client, onBack, onNext, onClose}: any) => {

    const [grantType, setGrantType] = useState<string>();
    const [sourceType, setSourceType] = useState<string>('API');
    
    const [form] = Form.useForm();

    useEffect(() => {
        console.log('client', client);
        form.setFieldsValue(client);
        const type = client?.sourceType || 'API';
        console.log('type', type);
        setSourceType(type);
    }, [client]);

    const requiredRule = (isRequired: boolean, message: string) => {
        return {
          required: isRequired,
          message: message,
        }
    }

    const onFinish = (values: any) => {
        const data = {...client, ...values, sourceType};
        onNext(data);
    }

    const toggleSourceType = (e: RadioChangeEvent) => {
        setSourceType(e.target.value);
    }

    return <Form 
        form={form}
        className="clients-page-form"
        onFinish={onFinish}
        style={{overflow: 'hidden', height: show ? 'auto' : '0rem'}}
        >
  
        <h2>Source Configuration</h2>

        <h3>Source Type</h3>
        <Radio.Group
            style={{ width: '100%', fontSize: "1rem" }}
            defaultValue={sourceType}
            value={sourceType}
            onChange={toggleSourceType}
        >
            <Space style={{width: '100%', justifyContent: 'space-evenly'}}>
                <Radio value="API">API</Radio>
                <Radio value="MANUAL">MANUAL</Radio>
            </Space>
        </Radio.Group>

        {sourceType === 'API' 
        && <>
        <h3>Source API</h3>
        <label>Data Endpoint *</label>
        <Form.Item
            name="tdSourceURL"
            rules={[
            requiredRule(sourceType === 'API', "Source URL is required.")
            ]}
        >
            <Input />
        </Form.Item>
    
        <h3>OAuth 2.0</h3>
        <label>Authentication Endpoint *</label>
        <Form.Item
            name="tdAuthURL"
            rules={[
            requiredRule(sourceType === 'API', "Authentication URL is required.")
            ]}
        >
            <Input />
        </Form.Item>
        <label>Grant Type *</label>
        <Form.Item
            name="tdGrantType"
            rules={[
            requiredRule(sourceType === 'API', "Grant Type is required.")
            ]}
        >
            <Select options={grantTypes} onChange={setGrantType} />
        </Form.Item>
        <label>Client ID *</label>
        <Form.Item
            name="tdClientId"
            rules={[
            requiredRule(sourceType === 'API', "Client ID is required.")
            ]}
        >
            <Input />
        </Form.Item>
    
        {'PASSWORD' === grantType 
        && (<>
            <h3>User Credentials</h3>
            <Row gutter={16}>
            <Col span={12}>
                <label>Username *</label>
                <Form.Item
                name="tdUsername"
                // rules={[
                //   requiredRule("Username is required.")
                // ]}
                >
                <Input />
                </Form.Item>
            </Col>
            <Col span={12}>
                <label>Password *</label>
                <Form.Item
                name="tdPassword"
                // rules={[
                //   requiredRule("Password is required.")
                // ]}
                >
                <Input.Password />
                </Form.Item>
            </Col>
            </Row>
        </>)
        || (<>
            <label>Client Secret </label>
            <Form.Item
            name="tdClientSecret"
            // rules={[
            //   requiredRule("Client Secret is required.")
            // ]}
            >
            <Input.Password />
            </Form.Item>
        </>)}
        </> 
        || <>
        <h3>Source Reference</h3>
        <label>Paste the source reference here</label>
        <Form.Item
            name="sourceData"
            rules={[
                requiredRule(sourceType === 'MANUAL', "Source reference is required.")
            ]}
        >
            <Input.TextArea style={{height: '24rem'}}  />
        </Form.Item>
        </>}

        <Space direction="horizontal" align="end" style={{marginTop: '2rem', width: '100%', justifyContent: 'flex-end'}}>
            <Button type="default" key="cancel" onClick={onClose}>Cancel</Button>
            <Button type="primary" onClick={onBack}>Back</Button>
            <Button type="primary" htmlType="submit">Proceed</Button>
        </Space>
    </Form>
}

export default SourceConfiguration;