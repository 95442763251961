import './users-page.css';
import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Form, Modal, Space, Table, Tag, notification } from 'antd';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { addControlStyle } from './users-page-style';
import { User } from '../../model/user-model';
import { UserForm } from './user-form';
import { useNavigate } from 'react-router-dom';

import _isUndefined from 'lodash/isUndefined';
import { useAddUserMutation, useDeleteUserMutation, useLazyGetUsersQuery, useUpdateUserMutation } from '../../redux/api/users';

const UsersPage = () => {

  const menuItems = [
    {
      key: 'update',
      icon: <AiOutlineEdit />,
      label: 'Update User'
    },
    {
      key: 'delete',
      icon: <AiOutlineDelete />,
      label: 'Delete User'
    }
  ];

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 80,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 300,
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
      width: 240,
      // sorter: (a: string, b: string) => a.localeCompare(b)
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
      width: 240,
      // sorter: (a: string, b: string) => a.localeCompare(b)
    },
    {
      title: 'User Type',
      dataIndex: 'userType',
      key: 'userType',
      width: 160,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_: any, record: User) => {
        return <Dropdown
          trigger={['click']}
          menu={{
            items: menuItems,
            onClick: ({ key }) => handleUserAction(key, record)
          }}>
          <div style={{ cursor: 'pointer' }} onClick={(e) => e.preventDefault()}>
            <Space>
              <span className="dropdown-title">
                <BsThreeDotsVertical />
              </span>
            </Space>
          </div>
        </Dropdown>
      }
    },
  ];

  const [isAddOpen, setAddOpen] = useState<boolean>(false);
  const [isEditOpen, setEditOpen] = useState<boolean>(false);
  const [users, setUsers] = useState<User[]>([]);
  const [user, setUser] = useState<User>();
  const [page, setPage] = useState<any>();

  const [modal, contextHolder] = Modal.useModal();

  const [form] = Form.useForm();

  const [getUsers] = useLazyGetUsersQuery();
  const [postUser] = useAddUserMutation();
  const [putUser] = useUpdateUserMutation();
  const [removeUser] = useDeleteUserMutation();
  
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUsers = async () => {
      const users = await getUsers({viewableSize: 1000}).unwrap();
      const indexedUsers = users.items.map((user: User, index: number) => {
        return { ...user, key: index };
      });
      setUsers(indexedUsers);
      setPage(users.page);
    }
    fetchUsers();
  }, [getUsers]);

  /**
   * Opens the Add User Modal.
   */
  const openAddModal = (): void => {
    form.resetFields();
    setAddOpen(true);
  }

  /**
   * Adds a user by sending the user data to the server.
   * 
   * @param user the user to add
   */
  const addUser = async (user: User): Promise<void> => {

    try {

      console.log('user', user);
      
      const response = await postUser(user).unwrap();
      let newUsers: User[] = [...users, response];
      setUsers(newUsers);
      form.resetFields();
      setAddOpen(false);

      notification.success({
        message: "User Created!",
        description: `The user ${response.name} has been created.`
      });

    } catch (e) {
      console.log(e);
      notification.error({
        message: "Creating User Failed!",
        description: `An error occurred while creating the user`
      });
    }
  }

  /**
   * Updates the user information.
   * 
   * @param user 
   */
  const updateUser = async (user: User): Promise<void> => {

      try {
        const response: User = await putUser(user).unwrap();
        let ndx = users.findIndex(user => user.id === response.id);
        users[ndx] = response;
        let newUsers: User[] = [...users];
        setUsers(newUsers);
        form.resetFields();
        setEditOpen(false);

        notification.success({
          message: "User Updated!",
          description: `The user ${response.email} has been updated.`
        });

      } catch (e) {
        console.log(e);
        notification.error({
          message: "Updating User Failed!",
          description: `An error occurred while updating the user.`
        });
      }
  }
  
  /**
   * Deletes the user
   * 
   * @param user 
   */
  const deleteUser = async (user: User): Promise<void> => {
    try {
      const response = await removeUser(user).unwrap();

      const temp = [...users];
      let ndx = temp.findIndex(user => user.id === response.id);
      temp.splice(ndx, 1);
      let newUsers: User[] = [...temp];
      setUsers(newUsers);

      notification.success({
        message: "User Deleted!",
        description: `The user ${user.email} has been deleted.`
      });

    } catch (e) {
      console.log(e);
      notification.error({
        message: "Deleting User Failed!",
        description: `An error occurred while deleting the user.`
      });
    }
  }

  /**
   * Closes the add user modal.
   */
  const closeAddModal = (): void => {
    setAddOpen(false);
  }

  const closeEditModal = (): void => {
    setEditOpen(false);
  }
  
  const onDelete = (user: User) => {

    modal.confirm({

      title: 'Delete the user',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure to delete this user?',
      okText: 'Ok',
      cancelText: 'Cancel',
      onOk: () => deleteUser(user),
    });
  };

  /**
   * Handles the click action on the action button of the user
   * in the user list (the 3-dot vertical button on the right-most
   * column)
   * 
   * @param key the key of the menu item that was clicked
   * @param user the user that was clicked
   */

  const handleUserAction = (key: string, user: User) => {
    setUser(user);
    switch (key) {
      case 'update': setEditOpen(true); form.setFieldsValue(user); break;
      case 'delete': onDelete(user); break;
    }
  }

  return (
    <>
      {contextHolder}
      <div style={addControlStyle}>
        <h1>Admin Users</h1>
        <Button
          type="primary"
          onClick={openAddModal}
        >
          Add User
        </Button>
      </div>

      <Table dataSource={users} columns={columns} />

      <UserForm 
        title={'Add User'}
        open={isAddOpen}
        addUser={addUser}
        onClose={closeAddModal}
      />
      <UserForm 
        title={'User Information'}
        user={user}
        open={isEditOpen}
        updateUser={updateUser}
        onClose={closeEditModal}
      />
    </>
  );
};
export default UsersPage;

