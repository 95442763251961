import { Button, Space } from "antd";

const NoChannelConfiguration = ({show, client, onBack, onSave, onClose}: any) => {

    const onFinish = () => {
        onSave(client);
    }

    return <div 
        className="clients-page-form"
        style={{overflow: 'hidden', height: show ? 'auto' : '0rem'}}
        >
  
        <h2>No channel will be configured for this client</h2>
        
        <Space direction="horizontal" align="end" style={{marginTop: '2rem', width: '100%', justifyContent: 'flex-end'}}>
            <Button type="default" key="cancel" onClick={onClose}>Cancel</Button>
            <Button type="primary" onClick={onBack}>Back</Button>
            <Button type="primary" onClick={onFinish}>Save</Button>
        </Space>
    </div>
  }

  export default NoChannelConfiguration;