import React from 'react';
import { ConfigProvider, Flex, Typography, Button, notification } from 'antd';

import MainContainerLayout from '../../layout/pdf-layout/main-container-layout';
import EditorLayout from '../../layout/pdf-layout/editor-layout';
import PropertyLayout from '../../layout/pdf-layout/property-layout';
import CanvasLayout from '../../layout/pdf-layout/canvas-layout';
import ComponentLayout from '../../layout/pdf-layout/component-layout';
import { pageHeaderContainerStyle, pageHeaderSaveButtonLblStyle, pageHeaderSaveButtonStyle, pageHeaderTitleStyle } from './page-style';
import Content from '../../component/pdf/content';
import Component from '../../component/pdf/component';
import Properties from '../../component/pdf/properties';
import { Client } from '../../model/client-model';
import { useLazyGetClientQuery } from '../../redux/api/clients';
import componentCreatorProps from '../../component/pdf/creator';
import { ComponentProps } from '../../component/pdf/model';

import _deepclone from "lodash/cloneDeep";
import { useLazyGetDataFieldsQuery } from '../../redux/api/data-maps';
import { useGetComponentMapMutation, useSaveComponentMapMutation } from '../../redux/api/component-maps';
import { useParams } from 'react-router-dom';

const { Text } = Typography;

interface Props { }

const PDFBuilderPage: React.FC<Props> = (): JSX.Element => {
  const [client, setClient] = React.useState<Client>();
  const [clients, setClients] = React.useState<Array<Client>>([]);

  const [component, setComponent] = React.useState<ComponentProps | undefined>();
  const [components, setComponents] = React.useState<Array<ComponentProps>>([]);

  const [dataMapFields, setDataMapFields] = React.useState([]);

  const [getClients] = useLazyGetClientQuery();
  const [getDataMapFields] = useLazyGetDataFieldsQuery();
  const [getComponentMap] = useGetComponentMapMutation();
  const [saveComponentMap] = useSaveComponentMapMutation();

  const { clientId } = useParams();

  React.useEffect(() => {
    const fetchClients = async () => {
      const clients = await getClients({}).unwrap();
      const indexedClients = clients.map((client: Client, index: number) => {
        return { ...client, key: index };
      });
      setClients(indexedClients);

      if (clientId) {
        const defaultClientId: number = parseInt(clientId);
        const defaultClient: Client = clients.find((client: Client) => client.id === defaultClientId);
        setClient(defaultClient);
      }
    }
    fetchClients();
  }, [getClients]);

  React.useEffect(() => {
    const fetchComponentMap = async () => {
      if (client) {
        try {
          let data = await getComponentMap(client.id).unwrap();
          setComponents(data);
        } catch (e) {
          console.log(e);
        }
      }
    }

    const fetchDataMapFields = async () => {
      if (client) {
        try {
          const data = await getDataMapFields(client.id).unwrap();
          setDataMapFields(data.map((field: string) => {
            return { value: field, label: field };
          }));
        } catch (e) {
          console.log(e);
          notification.error({
            message: "Data Fields Error!",
            description: `An error occurred while fetching the trusted driver data fields.`
          });
        }
      }
    }

    fetchDataMapFields()
    fetchComponentMap();
  }, [client, getComponentMap, getDataMapFields])

  const addNewComponent = (componentType: string, isParent: boolean) => {
    const newComponent = componentCreatorProps(componentType, isParent);
    const data = [...components, newComponent];
    setComponents(data);
    setComponent(newComponent);
  }

  const findKeyComponent = (array: Array<ComponentProps>, key?: string): any => {
    for (let i = 0; i < array.length; i++) {
      let item = array[i];
      if (item.key === key) {
        return item;
      }
      if (item.children) {
        let foundItem = findKeyComponent(item.children, key);
        if (foundItem) {
          return foundItem;
        }
      }
    }
  }

  const onChangeField = (cP: ComponentProps, field: string, value: any) => {
    setComponent((prevComponent: any) => {
      let cloneComponent = _deepclone(prevComponent);

      setComponents((prevComponents) => {
        let cloneComponents = _deepclone(prevComponents)
        let parentNode: any = findKeyComponent(cloneComponents, cP.key);
        if (parentNode) {
          parentNode[field] = value;
          if (parentNode.showCustomPadding) {
            parentNode['padding'] = 'Custom';
          }
          cloneComponent = parentNode;
          return cloneComponents;
        }
        return cloneComponents;
      })
      return cloneComponent;
    })
  }


  const onChangeComponentType = (cP: ComponentProps, field: string, value: any) => {
    setComponent((prevComponent: any) => {
      let cloneComponent = _deepclone(prevComponent);

      setComponents((prevComponents) => {
        let cloneComponents = _deepclone(prevComponents)
        let parentNode: any = findKeyComponent(cloneComponents, cP.key);
        if (parentNode) {
          parentNode[field] = value;
          cloneComponent = parentNode;
          return cloneComponents;
        }
        return cloneComponents;
      })
      return cloneComponent;
    })
  }

  const addItemComponent = (cP: ComponentProps, type: string) => {
    setComponent((prevComponent: any) => {
      let cloneComponent = _deepclone(prevComponent);
      const newItemComponent = componentCreatorProps(type, false, cP.key);
      setComponents((prevComponents) => {
        let cloneComponents = _deepclone(prevComponents)
        let parentNode: ComponentProps = findKeyComponent(cloneComponents, cP.key);
        if (parentNode) {
          let children = [...parentNode.children];
          children.push(newItemComponent);
          parentNode.children = children;
          cloneComponent = parentNode;
          return cloneComponents;
        }
        return cloneComponents;
      })
      return cloneComponent;
    })
  }

  const removeItemComponent = (cP: ComponentProps) => {
    setComponent((prevComponent: any) => {
      let cloneComponent = _deepclone(prevComponent);
      setComponents((prevComponents) => {
        let cloneComponents = _deepclone(prevComponents)
        let parentNode: ComponentProps = findKeyComponent(cloneComponents, cP.key);
        if (parentNode && !cP.isParent) {
          let children = [...parentNode.children];
          const ndx = children.findIndex((child) => child.key === cloneComponent.key);
          children.splice(ndx, 1);
          parentNode.children = children;
          cloneComponent = parentNode;
          return cloneComponents;
        } else {
          const ndx = cloneComponents.findIndex((parent) => parent.key === cP.key);
          cloneComponents.splice(ndx, 1);
          cloneComponent = undefined;
          return cloneComponents;
        }
      })
      return cloneComponent;
    })
  }

  const saveTemplate = async () => {
    setComponent(undefined);
    if (client !== undefined && component === undefined) {
      const element = document.getElementById("canvas");
      const htmlTemplate = `<!DOCTYPE html>
      <html lang="en">
        <head>
        <style>
          @page {
            margin: 1cm;
            size: 21cm 29.7cm;
          }
        </style>
        </head>
        <body></body>
      </html>`;
      const domparser = new DOMParser();
      let doc = domparser.parseFromString(htmlTemplate, "text/html");
      if (element != null) {
        const clone = element.cloneNode(true);
        doc.body.appendChild(clone)
      }
      const docType = '<!DOCTYPE HTML>';
      const content = doc.documentElement.outerHTML;

      try {
        await saveComponentMap({
          clientId: client.id,
          htmlContent: docType.toString() + content.toString(),
          payload: components
        }).unwrap();

        notification.success({
          message: "Component Mapping Saved!",
          description: `The component mapping configuration has been created.`
        });
      } catch (e) {
        console.log(e);
        notification.error({
          message: "Saving Component Mapping Failed!",
          description: `An error occurred while saving the component mapping configuration.`
        });
      }
    }
  }

  return (
    <ConfigProvider wave={{ disabled: true }}>
      <MainContainerLayout>
        <Flex style={pageHeaderContainerStyle}>
          <Text style={pageHeaderTitleStyle}>
            PDF Builder
          </Text>
          <Button
            style={pageHeaderSaveButtonStyle}
            disabled={!client}
            onClick={() => saveTemplate()}
          >
            <Text style={pageHeaderSaveButtonLblStyle}>
              Save as PDF Template
            </Text>
          </Button>
        </Flex>

        <EditorLayout>
          <ComponentLayout>
            <Component
              client={client}
              clients={clients}
              setClient={setClient}
              addComponent={addNewComponent}
            />
          </ComponentLayout>

          <CanvasLayout>
            <Content
              component={component}
              components={components}
              setSelectedComponent={setComponent}
            />
          </CanvasLayout>

          <PropertyLayout>
            {
              component &&
              <Properties
                dataMapFields={dataMapFields}
                component={component}
                onChangeField={onChangeField}
                onChangeComponentType={onChangeComponentType}
                addItemComponent={addItemComponent}
                removeItemComponent={removeItemComponent}
              />
            }
          </PropertyLayout>

        </EditorLayout>
      </MainContainerLayout>
    </ConfigProvider>
  )
}

export default PDFBuilderPage;