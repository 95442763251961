import { apiForPrivate } from '.';
import { DATA_MAP_ENDPOINT } from '../../config/ap';

const apiDataMaps = apiForPrivate.injectEndpoints({
  overrideExisting: true,
  endpoints: build => ({
    getDataMapSource: build.query({
      query: (clientId) => `${DATA_MAP_ENDPOINT.DATA_MAPS_SOURCE_DATA}?clientId=${clientId}`,
    }),
    getDataFields: build.query({
      query: (clientId) => `${DATA_MAP_ENDPOINT.DATA_MAPS_SOURCE}?clientId=${clientId}`,
    }),
    getDataMap: build.query({
      query: (clientId) => `${DATA_MAP_ENDPOINT.DATA_MAPS}?clientId=${clientId}`,
    }),
    saveDataMapSource: build.mutation({
      query: (payload) => ({
        url: `${DATA_MAP_ENDPOINT.DATA_MAPS_SOURCE_DATA}?clientId=${payload.clientId}`,
        method: 'POST',
        body: payload.source,
      }),
    }),
    saveDataMap: build.mutation({
      query: payload => ({
        url: `${DATA_MAP_ENDPOINT.DATA_MAPS}`,
        method: 'POST',
        body: payload,
      }),
    }),
  })
});

export const {
  useLazyGetDataMapSourceQuery,
  useLazyGetDataFieldsQuery,
  useLazyGetDataMapQuery,
  useSaveDataMapSourceMutation,
  useSaveDataMapMutation,
} = apiDataMaps;