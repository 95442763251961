import { Button, Col, Form, Input, Row, Space } from "antd";

const SFTPConfiguration = ({show, client, onBack, onSave, onClose}: any) => {

    const [form] = Form.useForm();

    const requiredRule = (isRequired: boolean, message: string) => {
        return {
          required: isRequired,
          message: message,
        }
    }

    const onFinish = (sftpChannel: any) => {
        const data = {...client, ...sftpChannel};
        onSave(data);
    }

    return <Form 
        form={form}
        className="clients-page-form"
        onFinish={onFinish}
        style={{overflow: 'hidden', height: show ? 'auto' : '0rem'}}
        >
  
        <h2>Secure FTP Configuration</h2>
        
        <h3>Network Configuration</h3>
        <Row gutter={16}>
            <Col span={12}>
            <label>Protocol *</label>
            <Form.Item
                name="targetProtocol"
                rules={[requiredRule(client?.channelType === 'sFTP', "Protocol is required.")]}
            >
                <Input />
            </Form.Item>
            </Col>
            <Col span={12}>
            <label>Host *</label>
            <Form.Item
                name="targetHost"
                rules={[requiredRule(client?.channelType === 'sFTP', "Host is required.")]}
            >
                <Input />
            </Form.Item>
            </Col>
        </Row>
        <Row gutter={16}>
            <Col span={12}>
            <label>Username</label>
            <Form.Item
                name={['sftpChannel', 'targetUsername']}
            >
                <Input />
            </Form.Item>
            </Col>
            <Col span={12}>
            <label>Password</label>
            <Form.Item
                name={['sftpChannel', 'targetPassword']}
            >
                <Input.Password />
            </Form.Item>
            </Col>
        </Row>
        <h3>File and Directory Management</h3>
        <label>Target Directory</label>
        <Form.Item
            name={['sftpChannel', 'targetDirectoryName']}
        >
            <Input />
        </Form.Item>
        <label>File Name *</label>
        <Form.Item
            name={['sftpChannel', 'fileName']}
            rules={[requiredRule(client?.channelType === 'sFTP', "Filename is required.")]}
        >
            <Input />
        </Form.Item>

        <Space direction="horizontal" align="end" style={{marginTop: '2rem', width: '100%', justifyContent: 'flex-end'}}>
            <Button type="default" key="cancel" onClick={onClose}>Cancel</Button>
            <Button type="primary" onClick={onBack}>Back</Button>
            <Button type="primary" htmlType="submit">Save</Button>
        </Space>
    </Form>
  }

  export default SFTPConfiguration;