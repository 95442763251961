import { useEffect, useState } from "react";
import { Space, Steps } from "antd";
import { Client } from "../../model/client-model";
import ClientConfiguration from "./client-configuration";
import SourceConfiguration from "./source-configuration";

import _isEqual from "lodash/isEqual";
import TargetConfiguration from "./target-configuration";

const steps = [
  { title: 'Client' },
  { title: 'Source' },
  { title: 'Target' },
];

/**
 * 
 * @param param0 
 * @returns 
 */
const ClientForm = ({ client, onClose, addClient, updateClient }: any) => {

  const [step, setStep] = useState<number>(0);
  const [clientData, setClientData] = useState<any>();
  
  useEffect(() => {
    setClientData(client);
  }, [client]);

  const onBack = () => {
    const backStep = step - 1;
    setStep(backStep);
  }

  const onNext = (client: any) => {
    setClientData(client);
    const nextStep = step + 1;
    setStep(nextStep);
  }

  const onSave = (client: Client) => {
    if (addClient) addClient(client);
    if (updateClient) updateClient(client);
  }

  return <Space direction="vertical" style={{ display: 'flex', marginTop: '2rem' }}>
      <Steps current={step} items={steps} />
      <ClientConfiguration show={step === 0} client={clientData} onNext={onNext} onClose={onClose}/>
      <SourceConfiguration show={step === 1} client={clientData} onBack={onBack} onNext={onNext} onClose={onClose}/>
      <TargetConfiguration show={step === 2} client={clientData} onBack={onBack} onSave={onSave} onClose={onClose}/>
    </Space>
}

export { ClientForm };



