import { Image, Flex, Grid, notification } from "antd";
import LoginImage from "../../assets/images/login-image.png";
import Title from "antd/es/typography/Title";
import LoginForm from "../../component/form/login-form/login-form";
import { mainContainerStyle, loginFormContainerStyle, smLoginImageStyle, welcomeTextStyle, xlLoginImageStyle } from "./login-page-style";
import { useEffect } from "react";

const { useBreakpoint } = Grid;

const LoginPage = () => {

  const query = new URLSearchParams(window.location.search);
  const timeOut = query.get('timeOut');

  useEffect(() => {
    if (timeOut) {
      notification.error({message: "Your session has been timed out, log in again."});
    }
  }, [timeOut])
  
  const screens = useBreakpoint();

  return (
    <Flex
      gap={screens.xl ? 192 : 0}
      justify="center"
      align="center"
      style={{ ...mainContainerStyle, padding: screens.xl ? "0 120px" : "0" }}>
      <Image
        src={LoginImage}
        style={{
          ...xlLoginImageStyle,
          display: screens.xl ? "block" : "none",
        }}
        preview={false}
      />

      <Flex
        vertical
        justify="center"
        style={{
          ...loginFormContainerStyle,
          width: screens.xl ? "35%" : "100%",
          padding: screens.xl ? "0 72px" : "0 36px",
        }}>
        <Title level={1} style={welcomeTextStyle}>
          TrustedSync
        </Title>
        <Title level={3} style={welcomeTextStyle}>
          Welcome back!
        </Title>
        <LoginForm />
        <Image
          src={LoginImage}
          style={{
            ...smLoginImageStyle,
            display: screens.xl ? "none" : "block",
          }}
          preview={false}
        />
      </Flex>
    </Flex>
  );
};

export default LoginPage;
