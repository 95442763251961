import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { SERVER_DOMAIN } from '../../config/ap';
import { RootState } from '../store';
import { removeSessionCredentialsToStorage } from '../action/auth-action';

const BASE_QUERY = fetchBaseQuery({
  baseUrl: SERVER_DOMAIN,
});

const apiForPublic = createApi({
  reducerPath: 'apiPublic',
  baseQuery: BASE_QUERY,
  endpoints: () => ({}),
});

const BASE_QUERY_AUTH = fetchBaseQuery({
  baseUrl: SERVER_DOMAIN,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.session.accessToken;
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    headers.set('Content-Type', 'application/json');
    return headers;
  },
});

const baseQueryWithReauth = async (args:any, api:any, extraOptions:any) => {

  let result = await BASE_QUERY_AUTH(args, api, extraOptions);
  const status = result?.error?.status;
  if (status == 401) {
    api.dispatch(removeSessionCredentialsToStorage());
    window.location.href = '/login?timeOut=true';
  }

  return result;
};

// For api calls that require authorization header
const apiForPrivate = createApi({
  reducerPath: 'apiPrivate',
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
});

export { apiForPublic, apiForPrivate }